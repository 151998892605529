<template>
  <div id='app'>
    <router-view :settingsVisible='isSettingsVisible' />
  </div>
</template>

<script>

export default {
  name: 'App',
  props: {
    settingsVisible: {
      type: Boolean,
      default: false
    },
  },
  beforeCreate() {
    document.addEventListener('DOMContentLoaded', () => {
      this.loadStations();
    });
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpgradeUI = true;
      });
    }
  },
  methods: {
    loadStations() {
      this.$store.dispatch('loadStations');
    },
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    }
  },
  computed: {
    isSettingsVisible() {
      return this.settingsVisible;
    }
  },
};

</script>

<style lang='scss'>
@import '@/styles/global.scss';
@import '@/styles/reset.scss';

:root {
  --header-height: 30px;
  --settings-height: 190px;
  --transition-time: 0.31416s;
}

@include tablet {
  :root {
    --header-height: 40px;
    --settings-height: 160px;
  }
}

@include desktop {
  :root {
    --header-height: 50px;
    --settings-height: 150px;
  }
}

#app {
  margin: 0 auto;
  padding: 0 var(--space-sm);
}

</style>
