import Rata from '@/services/Rata';

const getTimeTableRow = (timeTableRows, type, timeType, station = null) => {
  const timeTableRow = timeTableRows.filter(function(row) {
    return ((station && row.stationShortCode === station) || !station) && row.type === type;
  })[0];
  if (!timeTableRow) return '';
  return timeTableRow[timeType];
};

const parseTrain = (train) => {
  return {
    passengerTrain: (train.trainCategory === 'Long-distance' ||  train.trainCategory === 'Commuter'),
    date: train.departureDate,
    trainName: train.trainType + ' ' + train.trainNumber.toString(),
    trainNumber: train.trainNumber.toString(),
    isCommuter: train.trainCategory === 'Commuter',
    commuterLineID: train.commuterLineID,
    timeTableRows: train.timeTableRows
  };
}

const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (
      !Object.hasOwnProperty.call(a, key) ||
      !Object.hasOwnProperty.call(b, key)
    ) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const mapTrainData = (train, timeTableRows, station = null) => {
  let currentTrain = mapToArrivalAndDepartureData(train, timeTableRows, station);

  currentTrain.track = currentTrain.departure.track
  ? currentTrain.departure.track
  : currentTrain.arrival.track;

  currentTrain.sortingTime = currentTrain.arrival.scheduled
    ? currentTrain.arrival.scheduled
    : currentTrain.departure.scheduled;

  currentTrain.bothCancelled = (
    currentTrain.arrival.cancelled && currentTrain.departure.cancelled
  ) || (
    !currentTrain.arrival.scheduled && currentTrain.departure.cancelled
  ) || (
    !currentTrain.departure.scheduled && currentTrain.arrival.cancelled
  );

  return currentTrain;
}

const mapToArrivalAndDepartureData = (item, rows, station = null) => {
  return { 
    ...item,
    arrival: {
      scheduled: getTimeTableRow(
        rows,
        'ARRIVAL',
        'scheduledTime',
        station ?? item.stationShortCode,
        ),
      actual: getTimeTableRow(
        rows,
        'ARRIVAL',
        'actualTime',
        station ?? item.stationShortCode,
      ),
      diff: getTimeTableRow(
        rows,
        'ARRIVAL',
        'differenceInMinutes',
        station ?? item.stationShortCode,
      ),
      track: getTimeTableRow(
        rows,
        'ARRIVAL',
        'commercialTrack',
        station ?? item.stationShortCode,
      ),
      cancelled: getTimeTableRow(
        rows,
        'ARRIVAL',
        'cancelled',
        station ?? item.stationShortCode,
      ),
      trainStopping: getTimeTableRow(
        rows,
        'ARRIVAL',
        'trainStopping',
        station ?? item.stationShortCode,
      )
    },
    departure: {
      scheduled: getTimeTableRow(
        rows,
        'DEPARTURE',
        'scheduledTime',
        station ?? item.stationShortCode,
      ),
      actual: getTimeTableRow(
        rows,
        'DEPARTURE',
        'liveEstimateTime',
        station ?? item.stationShortCode,
      ),
      diff: getTimeTableRow(
        rows,
        'DEPARTURE',
        'differenceInMinutes',
        station ?? item.stationShortCode,
      ),
      track: getTimeTableRow(
        rows,
        'DEPARTURE',
        'commercialTrack',
        station ?? item.stationShortCode,
      ),
      cancelled: getTimeTableRow(
        rows,
        'DEPARTURE',
        'cancelled',
        station ?? item.stationShortCode,
      ),
      trainStopping: getTimeTableRow(
        rows,
        'DEPARTURE',
        'trainStopping',
        station ?? item.stationShortCode,
      )
    },
    station: item.stationShortCode ?? null,
  }
}

export default {
  parseStationName(stationName) {
    let finalName = stationName;
    if (stationName.includes(' asema')) finalName = finalName.replace(' asema', '');
    if (stationName.includes('_')) finalName = finalName.split('_')[0];
    return finalName;
  },
  getLastStation(timetableList) {
    if (
      !timetableList ||
      !timetableList[timetableList.length - 1].stationShortCode
    ) return '';
    return timetableList[timetableList.length - 1].stationShortCode;
  },
  getStations() {
    return new Promise(resolve => {
      Rata()
        .get(`metadata/stations`)
        .then(res => res.data)
        .then(stationData => {
          const stations = stationData
            .filter(
              station => station.passengerTraffic  
            )
            .map(station => {
              return {
                ...station,
                stationName: this.parseStationName(station.stationName)
              };
            });
          resolve(stations);
        });
    });
  },
  getTrains(params) {
    return new Promise(resolve => {
      Rata()
        .get(
          `live-trains?arrived_trains=0&arriving_trains=${params.amount}&departed_trains=0&departing_trains=${params.amount}&station=${params.station}`
        )
        .then(res => res.data)
        .then(trainData => {
          const station = params.station;
          const trains = [];

          trainData
            .filter(train => train.trainType !== 'MV')
            .map((train) => {
              trains.push({
                ...parseTrain(train),
                ...mapTrainData(train, train.timeTableRows, station)
              });
              resolve(trains.sort(compareValues('sortingTime')));
            });
        });
    });
  },
  getTrain(params) {
    return new Promise(resolve => {
      Rata()
        .get(`trains/${params.date}/${params.trainNumber}`)
        .then(res => res.data[0])
        .then(trainData => {
          const timeTableRows = trainData.timeTableRows.map((train) => mapTrainData(train, trainData.timeTableRows));
          const timeTableRowsPerStation = timeTableRows.reduce((previousValue, currentValue) => {
            if (!previousValue.some((value) => value.station === currentValue.station)) {
              previousValue.push(currentValue);
            }
            return previousValue;
          },[]);

          resolve({
            ...trainData,
            ...parseTrain(trainData),
            timeTableRows: timeTableRowsPerStation.sort(compareValues('sortingTime'))
          });
        });
    });
  }
};
