<template>
  <ul>
    <li v-for="lang in langs" :key="lang">
      <button 
        class="button-reset"
        @click="setLang(lang)" 
        :class="{ 'is-vishidden': currentLang === lang }" 
        :disabled="currentLang === lang">
        <img :src="`${publicPath}flags/${lang}.svg`" :alt="`${lang} ${$t('language')}`">
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Langselect',
  data() {
    return { 
      langs: ['en', 'fi'],
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale;
    }
  },
  methods: {
    setLang(val) {
      const currentPath = this.$router.currentRoute.path;
      const languageDetector = new RegExp(`^/(${this.langs.join("|")})?/?`);
      const newPath = currentPath.replace(languageDetector, `/${val}/`);

      if (currentPath !== newPath) {
        this.$router.push({ path: newPath });
        this.$i18n.locale = val;
        document.documentElement.setAttribute('lang', val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  li {
    display: flex;
    align-items: center;
  }
}

button {
  height: 17px;
  width: 23px;
  margin: var(--space-sm) 0;
}

</style>