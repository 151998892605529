<template>
  <div class="userconfig">
    <div>
      <input 
        type="checkbox" 
        id="showDepartures" 
        v-model="showDepartures" 
        @keyup.enter="showDepartures = !showDepartures" 
        :disabled="!showArrivals" 
        class="is-vishidden">
      <label for="showDepartures">{{$t('showDepartures')}}</label>
    </div>

    <div>
      <input 
        type="checkbox" 
        id="showArrivals" 
        v-model="showArrivals" 
        @keyup.enter="showArrivals = !showArrivals" 
        :disabled="!showDepartures" 
        class="is-vishidden">
      <label for="showArrivals">{{$t('showArrivals')}}</label>
    </div>

    <div>
      <input 
        type="checkbox" 
        id="showCommuter" 
        v-model="showCommuter" 
        @keyup.enter="showCommuter = !showCommuter" 
        :disabled="!showLongDistance" 
        class="is-vishidden">
      <label for="showCommuter">{{$t('showCommuter')}}</label>
    </div>

    <div>
      <input 
        type="checkbox" 
        id="showLongDistance" 
        v-model="showLongDistance" 
        @keyup.enter="showLongDistance = !showLongDistance" 
        :disabled="!showCommuter" 
        class="is-vishidden">
      <label for="showLongDistance">{{$t('showLongDistance')}}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Userconfig',
  computed: {
    showDepartures: {
      get() {
        return this.$store.state.userConfig.showDepartures;
      },
      set() {
        this.$store.commit('TOGGLE_USER_DEPARTURES');
      }
    },
    showArrivals: {
      get() {
        return this.$store.state.userConfig.showArrivals;
      },
      set() {
        this.$store.commit('TOGGLE_USER_ARRIVALS');
      }
    },
    showCommuter: {
      get() {
        return this.$store.state.userConfig.showCommuter;
      },
      set() {
        this.$store.commit('TOGGLE_USER_COMMUTER');
      }
    },
    showLongDistance: {
      get() {
        return this.$store.state.userConfig.showLongDistance;
      },
      set() {
        this.$store.commit('TOGGLE_USER_LONGDISTANCE');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.userconfig {
  padding: var(--space-xs) 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    padding: var(--space-xs) 0;
    width: 50%;
  }
}

input {

  &:focus + label {
    &:after {
      border: 3px solid var(--color-white);
    }
  }
  &:focus:not(:checked) + label {
    &:after {
      border: 3px solid var(--color-accent);
    }
  }


  &:checked + label {
    &:before {
      background-color: var(--color-accent);
    }
    &:after {
      background-color: var(--color-accent-dark);
      transform: translate(80%, -50%);
    }
  }

  &:disabled + label {
    cursor: auto;
    filter: grayscale(1);
  }
}

label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: left;
  padding-left: 44px;
  font-size: var(--text-sm);

  &:before, &:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.3s ease;
  }
  &:before {
    left: 1px;
    width: 34px;
    height: 14px;
    background-color: var(--color-tertiary);
    border-radius: 8px;
  }
  &:after {
    left: 0;
    width: 20px;
    height: 20px;
    background-color: var(--color-border);
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  }
}

</style>
