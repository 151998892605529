import Vue from 'vue';
import Vuex from 'vuex';
import { format, parseISO } from 'date-fns';
import i18n from './i18n'
import App from './App.vue';
import router from '@/router/index.js';
import RataService from '@/services/RataService';
import STATIONS from './data/stations.js';
import wb from './registerServiceWorker';

Vue.prototype.$workbox = wb;
Vue.config.productionTip = false;
Vue.use(Vuex);

const getCurrentLangPath = (router) => {
  const currentPath = router.app.$router.currentRoute.path;
  const langMatch = new RegExp(`^/([a-z][a-z])?`);
  return currentPath.match(langMatch)[0];
};

const store = new Vuex.Store({
  state: {
    stations: STATIONS,
    stationsUpdated: false,
    selectedStation: '',
    selectedTrain: '',
    commuterLines: {
      hsl: ['I', 'P', 'K', 'Y', 'U', 'L', 'E', 'A'],
      vr: ['R', 'T', 'D', 'G', 'Z', 'M'],
    },
    userConfig: {
      showDepartures: true,
      showArrivals: true,
      showAmount: 50,
      showCommuter: true,
      showLongDistance: true,
    }
  },
  getters: {
    stations: state => state.stations,
    stationsUpdated: state => state.stationsUpdated,
    selectedStation: state => state.selectedStation,
  },
  mutations: {
    SET_STATIONS(state, stations) {
      state.stations = stations;
      state.stationsUpdated = true;
    },
    SET_SELECTED_STATION(state, station) {
      state.selectedStation = station;
      const newPath = `${getCurrentLangPath(router)}/station/${station}`;
      if (station && router.app.$route.path !== newPath) {
        router.app.$router.push(newPath);
      }
    },
    SET_SELECTED_TRAIN(state, trainRow) {
      const trainNumber = trainRow.trainNumber;
      const trainDate = trainRow.date;
      state.selectedTrain = trainNumber;
      const newPath = `${getCurrentLangPath(router)}/train/${trainDate}/${trainNumber}`;
      if (trainNumber && trainDate && router.app.$route.path !== newPath) {
        router.app.$router.push(newPath);
      }
    },
    TOGGLE_USER_DEPARTURES(state) {
      state.userConfig.showDepartures = !state.userConfig.showDepartures;
    },
    TOGGLE_USER_ARRIVALS(state) {
      state.userConfig.showArrivals = !state.userConfig.showArrivals;
    },
    TOGGLE_USER_COMMUTER(state) {
      state.userConfig.showCommuter = !state.userConfig.showCommuter;
    },
    TOGGLE_USER_LONGDISTANCE(state) {
      state.userConfig.showLongDistance = !state.userConfig.showLongDistance;
    },
  },
  actions: {
    loadStations({ commit }) {
      return new Promise(resolve => {
        RataService.getStations()
        .then(stations => {
          commit('SET_STATIONS', stations);
          resolve(stations);
        });
      });
    }
  }
});

// Udate meta tags based on route
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  })
  .forEach(tag => document.head.appendChild(tag));
  next();
});

Vue.filter('toTime', function(timeString) {
  if (
    !timeString ||
    !parseISO(timeString) ||
    !format(parseISO(timeString), 'HH:mm')
  ) return '';
  return format(parseISO(timeString), 'HH:mm');
});

Vue.filter('toDate', function(timeString) {
  if (
    !timeString ||
    !parseISO(timeString) ||
    !format(parseISO(timeString), 'dd.MM.yyyy')
  ) return '';

  return format(parseISO(timeString), 'dd.MM.yyyy');
});


Vue.filter('lastStation', RataService.getLastStation);

Vue.filter('stationShortCodeToName', function(stationCode, stations) {
  if (!stationCode) return '';
  if (!stations.find((station) => station.stationShortCode === stationCode)) {
    console.info('Could not find station ', stationCode);
    return '';
  }
  return RataService.parseStationName(stations.find((station) => {
    return station.stationShortCode === stationCode;
  }).stationName);
});

new Vue({
  store,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
