<template>
  <div class="combobox">
    <div 
      class="autocomplete" 
      role="combobox"
      aria-haspopup="listbox"
      aria-labelledby="combobox-label"
      aria-owns="autocomplete-results"
      aria-controls="autocomplete-results"
      :aria-expanded="isOpen ? 'true' : 'false'"
      :class="{ 'is-open': getIsOpen() }"
    >
      <input 
        type="text" 
        id="combobox"
        @click="inputChange"
        :value="searchQuery"
        @input="e => searchQuery = e.target.value"
        @keypress="inputChange" @keyup.down="onArrowDown" @keyup.up="onArrowUp" @keyup.enter="onEnter" 
        aria-multiline="false"
        aria-autocomplete="list" 
        :aria-activedescendant="activedescendant"
      />
      <label id="combobox-label" for="combobox">{{$t('chooseStation')}}</label>
      <ul 
        id="autocomplete-results" 
        v-show="isOpen" 
        class="autocomplete-results" 
        role="listbox" 
        aria-labelledby="combobox-label"
        :class="{ 'is-open': getIsOpen() }">
        <li v-for="(result, i) in searchResults" 
          :key="i" 
          @click="setResult(result)" 
          class="autocomplete-result" 
          :class="{ 'is-active': isSelected(i) }"
          role="option"
          :id="getId(i)"
          :aria-selected="isSelected(i) ? 'true' : 'false'">
          {{ result.stationName }}
        </li>
        <li v-if="searchResults.length < 1" 
          class="autocomplete-result no-results">
          {{ $t('noResults') }}
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
// Based on https://codepen.io/alligatorio/pen/XZoXqy

const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length != b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export default {
  name: 'Combobox',
  data() {
    return {
      isOpen: false,
      searchResults: [],
      searchQuery: '',
      selectedStation: null,
      activeResultCounter: 0,
      activedescendant: ''
    };
  },
  computed: {
    stations() {
      return this.$store.getters.stations;
    },
  },
  methods: {
    inputChange() {
      this.filterResults();
      this.isOpen = true;
    },
    filterResults() {
      this.searchResults = this.stations.filter(item => {
        return item.stationName.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1;
      });
    },
    setResult(result) {
      if (result) {
        this.$store.commit('SET_SELECTED_STATION', result.stationShortCode);
        this.searchQuery = result.stationName;
        this.selectedStation = result;
      }
      setTimeout(() => {this.isOpen = false;}, 0); // End of callstack
    },
    setEmpty() {
      this.$store.commit('SET_SELECTED_STATION', undefined);
      this.selectedStation = undefined;
      this.searchQuery = '';
    },
    onArrowDown() {
      if (this.isOpen) {
        if (this.activeResultCounter < this.searchResults.length - 1) {
          this.activeResultCounter = this.activeResultCounter + 1;
          this.setActiveDescendent();
        }
      }
    },
    onArrowUp() {
      if (this.isOpen) {
        if (this.activeResultCounter > 0) {
          this.activeResultCounter = this.activeResultCounter - 1;
          this.setActiveDescendent();
        }
      }
    },
    onEnter() {
      this.setResult(this.searchResults[this.activeResultCounter]);
      this.activeResultCounter = 0;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
      }
    },
    setActiveDescendent() {
      this.activedescendant = this.getId(this.activeResultCounter);
    },
    isSelected(index) {
      return index === this.activeResultCounter;
    },
    getIsOpen() {
      return this.isOpen;
    },
    getId(index) {
      return `result-option-${index}`;
    },
    updateComboboxFromRoute() {
      if (this.$route.params && this.$route.params.station) {
        const paramStation = this.$route.params.station;
        const selectedParamStation = this.stations.filter(station => station.stationShortCode === paramStation)[0];
        if (selectedParamStation) {
          this.setResult(selectedParamStation);
        } else {
          this.setEmpty();
          // @todo: Add error handling
        }
      } else {
        this.setEmpty();
      }
    },
  },
  watch: {
    stations(val, oldVal) {
      if (!arraysEqual(val, oldVal)) {
        this.searchResults = val;
        if (this.selectedStation) {
          this.searchQuery = val.find(station => station.stationShortCode === this.selectedStation.stationShortCode).stationName;
        }
      }
    },
    searchQuery() {
      this.inputChange();
    },
    $route() {
      this.updateComboboxFromRoute();
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.updateComboboxFromRoute();
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped lang="scss">

.combobox {
  padding: var(--space-sm) 0;
  padding-top: 0;
  position: relative;
  width: 100%;
}

.autocomplete {
  position: relative;
  display: block;
  color: var(--color-primary);
  padding-top: var(--space-md);

  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 7px 0 7px;
    border-color: var(--color-accent-dark) transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: var(--space-xs);
    pointer-events: none;
  }
  &.is-open:after {
    border-color: transparent transparent var(--color-accent) transparent;
    border-width: 0 7px 9px 7px;
  }

  label {
    position: absolute;
    display: block;
    top: var(--space-md);
    left: 0;
    transition: 0.2s ease all;
    pointer-events: none;
    cursor: text;
  }

  input {
    padding: var(--space-xs) 0;
    display: block;
    width: 100%;
    border: none;
    font-size: var(--text-base-size);
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
    padding-right: var(--space-lg);

    &:invalid {
      box-shadow: none;
    }
    &:valid, &:focus {
      & ~ label {
        top: var(--space-xs);
        font-size: var(--text-sm);
      }
    }
    &:focus {
      color: var(--color-primary);
      border-bottom-color: var(--color-accent);

      & ~ label {
        color: var(--color-accent-dark);
      }

      & ~ .autocomplete-results {
        max-height: 60vh; 
        height: auto;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
        transition: 0.2s ease-out height, step-start box-shadow 0.2s;
      }
    }
  }
}

.autocomplete-results {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background: var(--color-background);
  color: var(--color-primary);
  z-index: 2;
  transition: 0.2s ease-in height, step-end box-shadow 0.2s;
  
  &.is-open{
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    max-height: 60vh; 
    height: auto;
  }

  li {
    padding: var(--space-sm) var(--space-md);
    
    &:not(.no-results) {
      cursor: pointer;
      &:hover {
        background-color: var(--color-accent-light);
        color: var(--color-primary);
      }
    }

    &.is-active {
      background-color: var(--color-accent);
      color: var(--color-white);
    }
  }
}

</style>
