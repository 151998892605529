<template>
  <header>
    <h1 v-if="selectedStation">{{selectedStation | stationShortCodeToName(stations)}}</h1>
    <h1 v-else>{{$t("chooseStation")}}</h1>
    <div class="settingsToggle">
      <button @click="refreshFeed()" class="button-reset">
        <img src="../assets/svg/refresh-24px.svg" alt="">
        <span class="is-vishidden">{{$t("refresh")}}</span>
      </button>
      <button 
        @click="onClickButton()"
        :class="{'is-open' : isSettingsVisible}"
        class="button-reset">
        <img src="../assets/svg/settings-24px.svg" alt="">
        <span class="is-vishidden">{{$t("settings")}}</span>
      </button>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  props: {
    settingsVisible: Boolean,
  },
  computed: {
    isSettingsVisible() {
      return this.settingsVisible;
    },
    selectedStation() {
      return this.$store.getters.selectedStation;
    },
    stations() {
      return this.$store.getters.stations;
    },
  },
  methods: {
    onClickButton() {
      this.$emit('settingsToggle', !this.settingsVisible);
    },
    refreshFeed() {
      this.$emit('refreshFeed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/shared.scss';

h1 {
  @extend %sign;
}

header {
  padding: var(--space-sm) 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-background);
  position: relative;
  z-index: 1;

  .settingsToggle {
    display: flex;

    button {
      padding: 0 var(--space-sm);
      display: flex;
      align-items: center;
      transition: transform var(--transition-time) linear, filter var(--transition-time) linear;
      // Filter calulcated to #006b71 by https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(15%) sepia(20%) saturate(2850%) hue-rotate(179deg) brightness(94%) contrast(95%);

      &.is-open {
        filter: none;
        transform: rotate(90deg);
      }
    }
  }
}
</style>