<template>
  <div class="train">
    <div class="header" v-if="printTrain">
      <h1>{{printTrain.commuterLineID}} {{printTrain.trainType}} {{printTrain.trainNumber}}</h1>
      <span>{{printTrain.departureDate | toDate}}</span>
    </div>
    <div class="loader" :class="{'is-loading' : loading}"></div>
    <table v-if="printTrain">
      <thead>
        <tr>
          <!-- <th scope="col"></th> -->
          <th v-if="showArrivals" scope="col">
            <span class="short">{{$t('arrivalShort')}}</span>
            <span class="long">{{$t('arrival')}}</span>
          </th>
          <th v-if="showDepartures" scope="col">
            <span class="short">{{$t('departureShort')}}</span>
            <span class="long">{{$t('departure')}}</span>
          </th>
          <th scope="col">{{$t('track')}}</th>
          <th scope="col" class="destination">
            <span class="short">{{$t('destinationShort')}}</span>
            <span class="long">{{$t('destination')}}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading && train">
        <template v-for="(timeTableRow, id) in printTrain.timeTableRows">
          <StationRow v-if="timeTableRow" :stationRow="timeTableRow" :key="id" /> 
        </template>
      </tbody>
    </table>
    <Footer role="contentinfo" />
  </div>
</template>

<script>
import StationRow from './StationRow.vue';
import RataService from '@/services/RataService';
import Footer from './Footer.vue';

export default {
  name: 'Train',
  components: {
    StationRow,
    Footer
  },
  data() {
    return {
      train: null,
      loading: false,
      printTrain: null,
      stations: this.$store.getters.stations,
    };
  },
  computed: {
    showArrivals() {
      return this.$store.state.userConfig.showArrivals;
    },
    showDepartures() {
      return this.$store.state.userConfig.showDepartures;
    },
  },
  methods: {
    refresh() {
      this.updateOnRouteChange();
    },
    fetchTrain(date, trainNumber) {
      this.loading = true;
      RataService.getTrain({
        date,
        trainNumber,
      }).then(train => {
        this.loading = false;
        this.train = train;
      });
    },
    updateOnRouteChange() {
      if (this.$route.params && this.$route.params.date && this.$route.params.trainNumber) {
        this.fetchTrain(this.$route.params.date, this.$route.params.trainNumber);
      } else {
        console.error('Component called without mandatory route parameters date and trainNumber');
      }
    },
    parseTrain() {
      this.printTrain = this.train;
    },
  },
  mounted() {
    this.updateOnRouteChange();
  },
  watch: {
    '$store.state.userConfig.showDepartures': function() {
      this.parseTrain();
    },
    '$store.state.userConfig.showArrivals': function() {
      this.parseTrain();
    },
    '$store.state.userConfig.showCommuter': function() {
      this.parseTrain();
    },
    '$store.state.userConfig.showLongDistance': function() {
      this.parseTrain();
    },
    train() {
      this.parseTrain();
    },
    $route() {
      this.updateOnRouteChange();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import '../styles/shared.scss';

h1 {
  @extend %sign;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: var(--space-sm) 0;
}

.station {
  overflow-x: scroll;
}

table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
}

th {
  padding: var(--space-xs) var(--space-xxs);
  text-align: center;
  font-weight: 300;
  width: 110px;
  @include tablet-up {  
    width: 130px;
  }
  @include desktop {  
    width: 200px;
  }
}

th.destination {
  text-align: left;
  width: unset;
}

thead tr {
  border-bottom: 2px solid var(--color-primary);
  text-transform: uppercase;
  font-size: var(--text-xs);
}

.long {
  display: none;
}

@include tablet-up {  
  .short {
    display: none;
  }
  .long {
    display: block;
  }
}

tbody {
  position: relative;
}

.loader.is-loading {
  border: 8px solid var(--color-tertiary);
  border-top: 8px solid var(--color-secondary);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  display: block;
  margin: 4rem auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadMore {
  margin: var(--space-md) auto 0 auto;
  display: block;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--color-accent);
  color: var(--color-white);
  padding: var(--space-md);
  font-size: var(--text-sm);
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: var(--color-accent-dark);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
</style>
