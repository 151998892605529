import i18n from '../i18n.js';
import Timetable from '@/components/Timetable.vue';
import Train from '@/components/Train.vue';

export default [
  {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (!['en', 'fi'].includes(lang)) return next('en');
      if (i18n.locale !== lang) {
        i18n.locale = lang;
        document.documentElement.setAttribute('lang', lang);
      }
      return next();
    },
    children: [
      {
        path: '',
        name: 'App',
        component: Timetable,
        props: {
          settingsVisible: true,
        },
        meta: {
          title: 'Home | Stoge',
          metaTags: [
            {
              name: 'description',
              content: 'Stoge shows timetables of passenger trains running on rails in Finland'
            },
          ]
        }
      },
      {
        path: 'station/:station',
        name: 'Station',
        component: Timetable,
        props: false,
        meta: {
          title: `Station | Stoge`,
          metaTags: [
            {
              name: 'description',
              content: 'Stoge shows timetables of passenger trains running on rails in Finland'
            },
          ]
        }
      },
      {
        path: 'train/:date/:trainNumber',
        name: 'Train',
        component: Train,
        props: false,
        meta: {
          title: `Train | Stoge`,
          metaTags: [
            {
              name: 'description',
              content: 'Stoge shows timetables of passenger trains running on rails in Finland'
            },
          ]
        }
      }
    ]
  },
  {
    path: '',
    redirect: '/en'
  }
]