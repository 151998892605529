// API response from 20-03-2020
export default [{
  "passengerTraffic": true,
  "stationName": "Ainola",
  "stationShortCode": "AIN",
}, {
  "passengerTraffic": true,
  "stationName": "Alavus",
  "stationShortCode": "ALV",
}, {
  "passengerTraffic": true,
  "stationName": "Aviapolis",
  "stationShortCode": "AVP",
}, {
  "passengerTraffic": true,
  "stationName": "Dragsvik",
  "stationShortCode": "DRA",
}, {
  "passengerTraffic": true,
  "stationName": "Eläinpuisto-Zoo",
  "stationShortCode": "EPZ",
}, {
  "passengerTraffic": true,
  "stationName": "Eno",
  "stationShortCode": "ENO",
}, {
  "passengerTraffic": true,
  "stationName": "Espoo",
  "stationShortCode": "EPO",
}, {
  "passengerTraffic": true,
  "stationName": "Haapajärvi",
  "stationShortCode": "HPJ",
}, {
  "passengerTraffic": true,
  "stationName": "Haapamäki",
  "stationShortCode": "HPK",
}, {
  "passengerTraffic": true,
  "stationName": "Haarajoki",
  "stationShortCode": "HAA",
}, {
  "passengerTraffic": true,
  "stationName": "Haksi",
  "stationShortCode": "HSI",
}, {
  "passengerTraffic": true,
  "stationName": "Hankasalmi",
  "stationShortCode": "HKS",
}, {
  "passengerTraffic": true,
  "stationName": "Hanko asema",
  "stationShortCode": "HNK",
}, {
  "passengerTraffic": true,
  "stationName": "Hanko-Pohjoinen",
  "stationShortCode": "HKP",
}, {
  "passengerTraffic": true,
  "stationName": "Harjavalta",
  "stationShortCode": "HVA",
}, {
  "passengerTraffic": true,
  "stationName": "Haukivuori",
  "stationShortCode": "HAU",
}, {
  "passengerTraffic": true,
  "stationName": "Heinävesi",
  "stationShortCode": "HNV",
}, {
  "passengerTraffic": true,
  "stationName": "Helsinki asema",
  "stationShortCode": "HKI",
}, {
  "passengerTraffic": true,
  "stationName": "Henna",
  "stationShortCode": "HNN",
}, {
  "passengerTraffic": true,
  "stationName": "Herrala",
  "stationShortCode": "HR",
}, {
  "passengerTraffic": true,
  "stationName": "Hiekkaharju",
  "stationShortCode": "HKH",
}, {
  "passengerTraffic": true,
  "stationName": "Hikiä",
  "stationShortCode": "HK",
}, {
  "passengerTraffic": true,
  "stationName": "Hinthaara",
  "stationShortCode": "HH",
}, {
  "passengerTraffic": true,
  "stationName": "Humppila",
  "stationShortCode": "HP",
}, {
  "passengerTraffic": true,
  "stationName": "Huopalahti",
  "stationShortCode": "HPL",
}, {
  "passengerTraffic": true,
  "stationName": "Hyvinkää",
  "stationShortCode": "HY",
}, {
  "passengerTraffic": true,
  "stationName": "Hämeenlinna",
  "stationShortCode": "HL",
}, {
  "passengerTraffic": true,
  "stationName": "Höljäkkä",
  "stationShortCode": "HÖL",
}, {
  "passengerTraffic": true,
  "stationName": "Iisalmi",
  "stationShortCode": "ILM",
}, {
  "passengerTraffic": true,
  "stationName": "Iittala",
  "stationShortCode": "ITA",
}, {
  "passengerTraffic": true,
  "stationName": "Ilmala asema",
  "stationShortCode": "ILA",
}, {
  "passengerTraffic": true,
  "stationName": "Imatra asema",
  "stationShortCode": "IMR",
}, {
  "passengerTraffic": true,
  "stationName": "Inkeroinen",
  "stationShortCode": "IKR",
}, {
  "passengerTraffic": true,
  "stationName": "Inkoo",
  "stationShortCode": "IKO",
}, {
  "passengerTraffic": true,
  "stationName": "Isokyrö",
  "stationShortCode": "IKY",
}, {
  "passengerTraffic": true,
  "stationName": "Joensuu asema",
  "stationShortCode": "JNS",
}, {
  "passengerTraffic": true,
  "stationName": "Jokela",
  "stationShortCode": "JK",
}, {
  "passengerTraffic": true,
  "stationName": "Jorvas",
  "stationShortCode": "JRS",
}, {
  "passengerTraffic": true,
  "stationName": "Joutseno",
  "stationShortCode": "JTS",
}, {
  "passengerTraffic": true,
  "stationName": "Juupajoki",
  "stationShortCode": "JJ",
}, {
  "passengerTraffic": true,
  "stationName": "Jyväskylä",
  "stationShortCode": "JY",
}, {
  "passengerTraffic": true,
  "stationName": "Jämsä",
  "stationShortCode": "JÄS",
}, {
  "passengerTraffic": true,
  "stationName": "Järvelä",
  "stationShortCode": "JR",
}, {
  "passengerTraffic": true,
  "stationName": "Järvenpää asema",
  "stationShortCode": "JP",
}, {
  "passengerTraffic": true,
  "stationName": "Kajaani",
  "stationShortCode": "KAJ",
}, {
  "passengerTraffic": true,
  "stationName": "Kannelmäki",
  "stationShortCode": "KAN",
}, {
  "passengerTraffic": true,
  "stationName": "Kannus",
  "stationShortCode": "KNS",
}, {
  "passengerTraffic": true,
  "stationName": "Karjaa",
  "stationShortCode": "KR",
}, {
  "passengerTraffic": true,
  "stationName": "Karkku",
  "stationShortCode": "KRU",
}, {
  "passengerTraffic": true,
  "stationName": "Kauhava",
  "stationShortCode": "KHA",
}, {
  "passengerTraffic": true,
  "stationName": "Kauklahti asema",
  "stationShortCode": "KLH",
}, {
  "passengerTraffic": true,
  "stationName": "Kauniainen",
  "stationShortCode": "KNI",
}, {
  "passengerTraffic": true,
  "stationName": "Kausala",
  "stationShortCode": "KA",
}, {
  "passengerTraffic": true,
  "stationName": "Kemi",
  "stationShortCode": "KEM",
}, {
  "passengerTraffic": true,
  "stationName": "Kemijärvi",
  "stationShortCode": "KJÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Kera",
  "stationShortCode": "KEA",
}, {
  "passengerTraffic": true,
  "stationName": "Kerava asema",
  "stationShortCode": "KE",
}, {
  "passengerTraffic": true,
  "stationName": "Kerimäki",
  "stationShortCode": "KIÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Kesälahti",
  "stationShortCode": "KTI",
}, {
  "passengerTraffic": true,
  "stationName": "Keuruu",
  "stationShortCode": "KEU",
}, {
  "passengerTraffic": true,
  "stationName": "Kiiala",
  "stationShortCode": "KIA",
}, {
  "passengerTraffic": true,
  "stationName": "Kilo",
  "stationShortCode": "KIL",
}, {
  "passengerTraffic": true,
  "stationName": "Kirkkonummi",
  "stationShortCode": "KKN",
}, {
  "passengerTraffic": true,
  "stationName": "Kitee",
  "stationShortCode": "KIT",
}, {
  "passengerTraffic": true,
  "stationName": "Kiuruvesi",
  "stationShortCode": "KRV",
}, {
  "passengerTraffic": true,
  "stationName": "Kivistö",
  "stationShortCode": "KTÖ",
}, {
  "passengerTraffic": true,
  "stationName": "Kohtavaara",
  "stationShortCode": "KOH",
}, {
  "passengerTraffic": true,
  "stationName": "Koivuhovi",
  "stationShortCode": "KVH",
}, {
  "passengerTraffic": true,
  "stationName": "Koivukylä",
  "stationShortCode": "KVY",
}, {
  "passengerTraffic": true,
  "stationName": "Kokemäki",
  "stationShortCode": "KKI",
}, {
  "passengerTraffic": true,
  "stationName": "Kokkola",
  "stationShortCode": "KOK",
}, {
  "passengerTraffic": true,
  "stationName": "Kolari",
  "stationShortCode": "KLI",
}, {
  "passengerTraffic": true,
  "stationName": "Kolho",
  "stationShortCode": "KLO",
}, {
  "passengerTraffic": true,
  "stationName": "Kontiomäki",
  "stationShortCode": "KON",
}, {
  "passengerTraffic": true,
  "stationName": "Koria",
  "stationShortCode": "KRA",
}, {
  "passengerTraffic": true,
  "stationName": "Korso",
  "stationShortCode": "KRS",
}, {
  "passengerTraffic": true,
  "stationName": "Kotka asema",
  "stationShortCode": "KTA",
}, {
  "passengerTraffic": true,
  "stationName": "Kotkan satama",
  "stationShortCode": "KTS",
}, {
  "passengerTraffic": true,
  "stationName": "Kouvola asema",
  "stationShortCode": "KV",
}, {
  "passengerTraffic": true,
  "stationName": "Kuopio asema",
  "stationShortCode": "KUO",
}, {
  "passengerTraffic": true,
  "stationName": "Kupittaa",
  "stationShortCode": "KUT",
}, {
  "passengerTraffic": true,
  "stationName": "Kylänlahti",
  "stationShortCode": "KYN",
}, {
  "passengerTraffic": true,
  "stationName": "Kymi",
  "stationShortCode": "KY",
}, {
  "passengerTraffic": true,
  "stationName": "Kyminlinna",
  "stationShortCode": "KLN",
}, {
  "passengerTraffic": true,
  "stationName": "Käpylä",
  "stationShortCode": "KÄP",
}, {
  "passengerTraffic": true,
  "stationName": "Lahti",
  "stationShortCode": "LH",
}, {
  "passengerTraffic": true,
  "stationName": "Laihia",
  "stationShortCode": "LAI",
}, {
  "passengerTraffic": true,
  "stationName": "Lapinlahti",
  "stationShortCode": "LNA",
}, {
  "passengerTraffic": true,
  "stationName": "Lappeenranta",
  "stationShortCode": "LR",
}, {
  "passengerTraffic": true,
  "stationName": "Lappila",
  "stationShortCode": "LAA",
}, {
  "passengerTraffic": true,
  "stationName": "Lappohja",
  "stationShortCode": "LPO",
}, {
  "passengerTraffic": true,
  "stationName": "Lapua",
  "stationShortCode": "LPA",
}, {
  "passengerTraffic": true,
  "stationName": "Leinelä",
  "stationShortCode": "LNÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Lempäälä",
  "stationShortCode": "LPÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Lentoasema",
  "stationShortCode": "LEN",
}, {
  "passengerTraffic": true,
  "stationName": "Leppävaara",
  "stationShortCode": "LPV",
}, {
  "passengerTraffic": true,
  "stationName": "Lieksa",
  "stationShortCode": "LIS",
}, {
  "passengerTraffic": true,
  "stationName": "Lievestuore",
  "stationShortCode": "LVT",
}, {
  "passengerTraffic": true,
  "stationName": "Loimaa",
  "stationShortCode": "LM",
}, {
  "passengerTraffic": true,
  "stationName": "Louhela",
  "stationShortCode": "LOH",
}, {
  "passengerTraffic": true,
  "stationName": "Lusto",
  "stationShortCode": "LUS",
}, {
  "passengerTraffic": true,
  "stationName": "Malmi",
  "stationShortCode": "ML",
}, {
  "passengerTraffic": true,
  "stationName": "Malminkartano",
  "stationShortCode": "MLO",
}, {
  "passengerTraffic": true,
  "stationName": "Martinlaakso",
  "stationShortCode": "MRL",
}, {
  "passengerTraffic": true,
  "stationName": "Masala",
  "stationShortCode": "MAS",
}, {
  "passengerTraffic": true,
  "stationName": "Mikkeli",
  "stationShortCode": "MI",
}, {
  "passengerTraffic": true,
  "stationName": "Misi",
  "stationShortCode": "MIS",
}, {
  "passengerTraffic": true,
  "stationName": "Mommila",
  "stationShortCode": "MLA",
}, {
  "passengerTraffic": true,
  "stationName": "Moskova_(Leningradski)",
  "stationShortCode": "MVA",
}, {
  "passengerTraffic": true,
  "stationName": "Muhos",
  "stationShortCode": "MH",
}, {
  "passengerTraffic": true,
  "stationName": "Muurola",
  "stationShortCode": "MUL",
}, {
  "passengerTraffic": true,
  "stationName": "Myllykoski",
  "stationShortCode": "MKI",
}, {
  "passengerTraffic": true,
  "stationName": "Myllymäki",
  "stationShortCode": "MY",
}, {
  "passengerTraffic": true,
  "stationName": "Myyrmäki",
  "stationShortCode": "MYR",
}, {
  "passengerTraffic": true,
  "stationName": "Mäkkylä",
  "stationShortCode": "MÄK",
}, {
  "passengerTraffic": true,
  "stationName": "Mäntsälä",
  "stationShortCode": "MLÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Mäntyharju",
  "stationShortCode": "MR",
}, {
  "passengerTraffic": true,
  "stationName": "Nastola",
  "stationShortCode": "NSL",
}, {
  "passengerTraffic": true,
  "stationName": "Nikkilä",
  "stationShortCode": "NLÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Nivala",
  "stationShortCode": "NVL",
}, {
  "passengerTraffic": true,
  "stationName": "Nokia",
  "stationShortCode": "NOA",
}, {
  "passengerTraffic": true,
  "stationName": "Nurmes",
  "stationShortCode": "NRM",
}, {
  "passengerTraffic": true,
  "stationName": "Oitti",
  "stationShortCode": "OI",
}, {
  "passengerTraffic": true,
  "stationName": "Orivesi",
  "stationShortCode": "OV",
}, {
  "passengerTraffic": true,
  "stationName": "Orivesi keskusta",
  "stationShortCode": "OVK",
}, {
  "passengerTraffic": true,
  "stationName": "Oulainen",
  "stationShortCode": "OU",
}, {
  "passengerTraffic": true,
  "stationName": "Oulu asema",
  "stationShortCode": "OL",
}, {
  "passengerTraffic": true,
  "stationName": "Oulunkylä",
  "stationShortCode": "OLK",
}, {
  "passengerTraffic": true,
  "stationName": "Paimenportti",
  "stationShortCode": "PTI",
}, {
  "passengerTraffic": true,
  "stationName": "Paltamo",
  "stationShortCode": "PTO",
}, {
  "passengerTraffic": true,
  "stationName": "Parikkala",
  "stationShortCode": "PAR",
}, {
  "passengerTraffic": true,
  "stationName": "Parkano",
  "stationShortCode": "PKO",
}, {
  "passengerTraffic": true,
  "stationName": "Parola",
  "stationShortCode": "PRL",
}, {
  "passengerTraffic": true,
  "stationName": "Pasila asema",
  "stationShortCode": "PSL",
}, {
  "passengerTraffic": true,
  "stationName": "Pasila autojuna-asema",
  "stationShortCode": "PAU",
}, {
  "passengerTraffic": true,
  "stationName": "Pello",
  "stationShortCode": "PEL",
}, {
  "passengerTraffic": true,
  "stationName": "Petäjävesi",
  "stationShortCode": "PVI",
}, {
  "passengerTraffic": true,
  "stationName": "Pieksämäki asema",
  "stationShortCode": "PM",
}, {
  "passengerTraffic": true,
  "stationName": "Pietari_(Finljandski)",
  "stationShortCode": "PTR",
}, {
  "passengerTraffic": true,
  "stationName": "Pietari_(Ladozhski)",
  "stationShortCode": "PTL",
}, {
  "passengerTraffic": true,
  "stationName": "Pihlajavesi",
  "stationShortCode": "PH",
}, {
  "passengerTraffic": true,
  "stationName": "Pitäjänmäki",
  "stationShortCode": "PJM",
}, {
  "passengerTraffic": true,
  "stationName": "Pohjois-Haaga",
  "stationShortCode": "POH",
}, {
  "passengerTraffic": true,
  "stationName": "Pori",
  "stationShortCode": "PRI",
}, {
  "passengerTraffic": true,
  "stationName": "Porvoo",
  "stationShortCode": "PRV",
}, {
  "passengerTraffic": true,
  "stationName": "Puistola",
  "stationShortCode": "PLA",
}, {
  "passengerTraffic": true,
  "stationName": "Pukinmäki",
  "stationShortCode": "PMK",
}, {
  "passengerTraffic": true,
  "stationName": "Punkaharju",
  "stationShortCode": "PUN",
}, {
  "passengerTraffic": true,
  "stationName": "Purola",
  "stationShortCode": "PUR",
}, {
  "passengerTraffic": true,
  "stationName": "Pyhäsalmi",
  "stationShortCode": "PHÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Pännäinen",
  "stationShortCode": "PNÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Pääskylahti",
  "stationShortCode": "PKY",
}, {
  "passengerTraffic": true,
  "stationName": "Rekola",
  "stationShortCode": "RKL",
}, {
  "passengerTraffic": true,
  "stationName": "Retretti",
  "stationShortCode": "REE",
}, {
  "passengerTraffic": true,
  "stationName": "Riihimäki asema",
  "stationShortCode": "RI",
}, {
  "passengerTraffic": true,
  "stationName": "Rovaniemi",
  "stationShortCode": "ROI",
}, {
  "passengerTraffic": true,
  "stationName": "Runni",
  "stationShortCode": "RNN",
}, {
  "passengerTraffic": true,
  "stationName": "Ruukki",
  "stationShortCode": "RKI",
}, {
  "passengerTraffic": true,
  "stationName": "Ryttylä",
  "stationShortCode": "RY",
}, {
  "passengerTraffic": true,
  "stationName": "Salo",
  "stationShortCode": "SLO",
}, {
  "passengerTraffic": true,
  "stationName": "Santala",
  "stationShortCode": "STA",
}, {
  "passengerTraffic": true,
  "stationName": "Saunakallio",
  "stationShortCode": "SAU",
}, {
  "passengerTraffic": true,
  "stationName": "Savio",
  "stationShortCode": "SAV",
}, {
  "passengerTraffic": true,
  "stationName": "Savonlinna asema",
  "stationShortCode": "SL",
}, {
  "passengerTraffic": true,
  "stationName": "Seinäjoki asema",
  "stationShortCode": "SK",
}, {
  "passengerTraffic": true,
  "stationName": "Siilinjärvi Asema",
  "stationShortCode": "SIJ",
}, {
  "passengerTraffic": true,
  "stationName": "Simpele",
  "stationShortCode": "SPL",
}, {
  "passengerTraffic": true,
  "stationName": "Siuntio",
  "stationShortCode": "STI",
}, {
  "passengerTraffic": true,
  "stationName": "Skogby",
  "stationShortCode": "SGY",
}, {
  "passengerTraffic": true,
  "stationName": "Sukeva",
  "stationShortCode": "SKV",
}, {
  "passengerTraffic": true,
  "stationName": "Suonenjoki",
  "stationShortCode": "SNJ",
}, {
  "passengerTraffic": true,
  "stationName": "Tammisaari",
  "stationShortCode": "TMS",
}, {
  "passengerTraffic": true,
  "stationName": "Tampere asema",
  "stationShortCode": "TPE",
}, {
  "passengerTraffic": true,
  "stationName": "Tapanila",
  "stationShortCode": "TNA",
}, {
  "passengerTraffic": true,
  "stationName": "Tavastila",
  "stationShortCode": "TSL",
}, {
  "passengerTraffic": true,
  "stationName": "Tervajoki",
  "stationShortCode": "TK",
}, {
  "passengerTraffic": true,
  "stationName": "Tervola",
  "stationShortCode": "TRV",
}, {
  "passengerTraffic": true,
  "stationName": "Tikkurila asema",
  "stationShortCode": "TKL",
}, {
  "passengerTraffic": true,
  "stationName": "Toijala",
  "stationShortCode": "TL",
}, {
  "passengerTraffic": true,
  "stationName": "Tolsa",
  "stationShortCode": "TOL",
}, {
  "passengerTraffic": true,
  "stationName": "Tornio-Itäinen",
  "stationShortCode": "TRI",
}, {
  "passengerTraffic": true,
  "stationName": "Tuomarila",
  "stationShortCode": "TRL",
}, {
  "passengerTraffic": true,
  "stationName": "Turenki",
  "stationShortCode": "TU",
}, {
  "passengerTraffic": true,
  "stationName": "Turku asema",
  "stationShortCode": "TKU",
}, {
  "passengerTraffic": true,
  "stationName": "Turku satama",
  "stationShortCode": "TUS",
}, {
  "passengerTraffic": true,
  "stationName": "Tuuri",
  "stationShortCode": "TUU",
}, {
  "passengerTraffic": true,
  "stationName": "Tver",
  "stationShortCode": "TVE",
}, {
  "passengerTraffic": true,
  "stationName": "Uimaharju",
  "stationShortCode": "UIM",
}, {
  "passengerTraffic": true,
  "stationName": "Utajärvi",
  "stationShortCode": "UTJ",
}, {
  "passengerTraffic": true,
  "stationName": "Uusikylä",
  "stationShortCode": "UKÄ",
}, {
  "passengerTraffic": true,
  "stationName": "Vaala",
  "stationShortCode": "VAA",
}, {
  "passengerTraffic": true,
  "stationName": "Vaasa",
  "stationShortCode": "VS",
}, {
  "passengerTraffic": true,
  "stationName": "Vainikkala asema",
  "stationShortCode": "VNA",
}, {
  "passengerTraffic": true,
  "stationName": "Valimo",
  "stationShortCode": "VMO",
}, {
  "passengerTraffic": true,
  "stationName": "Vammala",
  "stationShortCode": "VMA",
}, {
  "passengerTraffic": true,
  "stationName": "Vantaankoski",
  "stationShortCode": "VKS",
}, {
  "passengerTraffic": true,
  "stationName": "Varkaus",
  "stationShortCode": "VAR",
}, {
  "passengerTraffic": true,
  "stationName": "Vehkala",
  "stationShortCode": "VEH",
}, {
  "passengerTraffic": true,
  "stationName": "Vihanti",
  "stationShortCode": "VTI",
}, {
  "passengerTraffic": true,
  "stationName": "Vihtari",
  "stationShortCode": "VIH",
}, {
  "passengerTraffic": true,
  "stationName": "Viiala",
  "stationShortCode": "VIA",
}, {
  "passengerTraffic": true,
  "stationName": "Viinijärvi",
  "stationShortCode": "VNJ",
}, {
  "passengerTraffic": true,
  "stationName": "Viipuri",
  "stationShortCode": "VYB",
}, {
  "passengerTraffic": true,
  "stationName": "Villähde",
  "stationShortCode": "VLH",
}, {
  "passengerTraffic": true,
  "stationName": "Vilppula",
  "stationShortCode": "VLP",
}, {
  "passengerTraffic": true,
  "stationName": "Vuonislahti",
  "stationShortCode": "VSL",
}, {
  "passengerTraffic": true,
  "stationName": "Ylistaro",
  "stationShortCode": "YST",
}, {
  "passengerTraffic": true,
  "stationName": "Ylitornio",
  "stationShortCode": "YTR",
}, {
  "passengerTraffic": true,
  "stationName": "Ylivieska",
  "stationShortCode": "YV",
}, {
  "passengerTraffic": true,
  "stationName": "Ähtäri",
  "stationShortCode": "ÄHT",
}]