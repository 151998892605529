<template>
  <tr>
    <template v-if="trainRow.passengerTrain">
      <td class="trainName" :class="{
          'hsl' : commuterLines.hsl.includes(trainRow.commuterLineID),
          'vr' : commuterLines.vr.includes(trainRow.commuterLineID),
          'long': !trainRow.isCommuter
        }">
        <button class="button-reset" @click="setTrain(trainRow)">
          <div v-if="trainRow.isCommuter">
            <span>
              {{trainRow.commuterLineID}}
            </span>
          </div>
          <span v-else>
            {{trainRow.trainName}}
          </span>
        </button>
      </td>
      <td class="arrival" v-if="showArrivals">
        <div v-if="trainRow.arrival.scheduled">
          {{trainRow.arrival.scheduled | toTime}}
        </div>
        <div class="alert cancelled" v-if="trainRow.arrival.cancelled">
          <span v-if="!trainRow.bothCancelled" v-html="$t('cancelled')"></span>
        </div>
        <div class="alert" v-else-if="trainRow.arrival.diff > 1 && trainRow.arrival.actual">
          ~{{trainRow.arrival.actual | toTime}}
        </div>
      </td>
      <td class="departure" v-if="showDepartures">
        <div v-if="trainRow.departure.scheduled">
          {{trainRow.departure.scheduled | toTime}}
        </div>
        <div class="alert cancelled" v-if="trainRow.departure.cancelled">
          <span v-if="!trainRow.bothCancelled" v-html="$t('cancelled')"></span>
        </div>
        <div class="alert" v-else-if="trainRow.departure.diff > 1 && trainRow.departure.actual">
          ~{{trainRow.departure.actual | toTime}}
        </div>
      </td>
      <td :class="{'track' : trainRow.track}">
        <div>
          <span v-if="trainRow.track">{{trainRow.track}}</span>
        </div>
      </td>
      <td class="destination">
        <button class="button-reset" @click="setStation(trainRow.timeTableRows)">
          {{trainRow.timeTableRows | lastStation | stationShortCodeToName(stations)}}
        </button>
        <div class="alert cancelled" v-if="trainRow.bothCancelled" v-html="$t('cancelled')"></div>
      </td>

    </template>
    <template v-if="trainRow.dateRow">
      <td class="dateRow" colspan="100">
        {{trainRow.dateRow | toDate}}
      </td>
    </template>
  </tr>
</template>

<script>
import RataService from '@/services/RataService';

export default {
  name: 'TrainRow',
  props: {
    trainRow: Object,
  },
  data() {
    return {
      stations: [],
    }
  },
  methods: {
    setStation(value) {
      this.$store.commit('SET_SELECTED_STATION', RataService.getLastStation(value));
    },
    setTrain(value) {
      this.$store.commit('SET_SELECTED_TRAIN', value);
    }
  },
  computed: {
    showArrivals() {
      return this.$store.state.userConfig.showArrivals;
    },
    showDepartures() {
      return this.$store.state.userConfig.showDepartures;
    },
    commuterLines() {
      return this.$store.state.commuterLines;
    },
  },
  created() {
    this.stations = this.$store.getters.stations;
  },
}
</script>

<style lang="scss" scoped>
tr {
  min-height: 48px;
  &:nth-child(even) {
    background-color: var(--color-border);
  }
  &:hover {
    background-color: var(--color-accent-light);
    .track div {
      background-color: var(--color-white);
    }
  }
}

td {
  vertical-align: middle;
  text-align: left;
  border-bottom: 2px solid var(--color-tertiary);
  padding: var(--space-xxs);
  font-weight: 500;
}

.trainName {
  text-align: center;
  div {
    margin: 0 auto;
  }

  &.hsl, &.vr {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: var(--body-line-height);
    }
  }
  &.hsl div {
    background-color: var(--color-commuter-hsl);
  }
  &.vr div {
    background-color: var(--color-commuter-vr);
  }
  &.long {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
}

.departure, .arrival {
  text-align: center;
}

.track {
  padding: 0;
  vertical-align: middle;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-accent-light);
    padding: 0;
    vertical-align: bottom;
    border-radius: 2px;
    height: 33px;
    width: 33px;
    margin: 0 auto;
  }

  span {
    line-height: var(--body-line-height);
    font-size: var(--text-sm);
  }
}

.destination, .trainName {
  button {
    color: var(--color-primary);
    font-size: var(--text-scale-ratio);
    font-weight: 500;
  }
}

.alert {
  color: var(--color-alert);
  text-transform: uppercase;
  font-weight: 500;
}

.cancelled {
  font-size: var(--text-sm);
}

.dateRow {
  text-align: center;
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: var(--space-xxxs);
  font-weight: 300;
}
</style>