<template>
  <div>
    
    <Header role="banner"
      :settings-visible="isSettingsVisible" 
      @settingsToggle="onSettingsToggle" 
      @refreshFeed="refreshFeed"/>

    <section class="main" role="main">

      <div class="settings" :class="{ 'is-open' : isSettingsVisible }">
        <Combobox />
        <Userconfig />
      </div>

      <Station ref="stationRef" :class="{ 'is-open' : isSettingsVisible }" />

    </section>

    <Footer role="contentinfo" />

  </div>
</template>

<script>
import Station from '@/components/Station';
import Combobox from '@/components/Combobox';
import Userconfig from '@/components/Userconfig';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: 'Timetable',
  components: {
    Station,
    Combobox,
    Userconfig,
    Header,
    Footer,
  },
  props: {
    settingsVisible: Boolean,
  },
  data() {
    return {
      isSettingsVisible: false,
    }
  },
  computed: {
    selectedStation() {
      return this.$store.getters.selectedStation;
    },
  },
  methods: {
    onSettingsToggle(val) {
      this.isSettingsVisible = val;
    },
    refreshFeed() {
      this.$refs.stationRef.refresh();
    },
  },
  mounted() {
    this.isSettingsVisible = this.settingsVisible;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.main {
  z-index: 0;
  position: relative;
}

.settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--space-md) 0;
  height: var(--settings-height);
  transform: translateY(-100%);
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  transition: none;

  > div {
    @include tablet {
      width: 48%;
    }
    @include desktop {
      width: 48%;
    }
  }

  &:not(.is-open) {
    visibility: hidden;
    transition: visibility var(--transition-time) linear calc(var(--transition-time) * 2);
  }
}

.station {
  z-index: -1;
  position: relative;
  transition: margin-top var(--transition-time) linear;
  &.is-open {
    margin-top: var(--settings-height);
  }
}
</style>