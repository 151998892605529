<template>
  <footer>

    <Langselect/>

    <div class="disclaimer">
      <span>{{$t('dataInfo1')}}</span><a href="https://www.digitraffic.fi/en/railway-traffic/">{{$t('dataInfo2')}}</a>. 
      <span>{{$t('dataInfo3')}}</span><a href="https://creativecommons.org/licenses/by/4.0/">{{$t('dataInfo4')}}</a>{{$t('dataInfo5')}}.
    </div>

    <div class="images">
      <img class="train" src="../assets/svg/train-24px.svg" alt="">
      <img class="logo" src="../assets/svg/stoge.svg" alt="Stoge logo">
    </div>
  </footer>
</template>

<script>
import Langselect from '@/components/Langselect';

export default {
  name: 'Footer',
  components: {
    Langselect,
  },
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: space-between;
  margin: var(--space-md) 0;
}

.disclaimer {
  font-size: var(--text-xs);
  display: flex;
  align-self: center;
  max-width: 50%;
  display: inline;
  span {
    padding-right: 0.2rem;
  }
}

.images {
  display: flex;
  flex-direction: row;

  img {
    height: var(--header-height);
    width: auto;
  }
}
</style>