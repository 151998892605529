<template>
  <tr 
    v-if="stationRow && (stationRow.arrival.trainStopping || stationRow.departure.trainStopping) && stationRow.commercialStop"
    v-bind:class="{ passed: stationRow.actualTime }"
  >
    <!-- <td class="stop">
      <span v-if="stationRow.actualTime">PASS</span>
      <span v-if="stationRow.liveEstimateTime">UP</span>
    </td> -->
    <td class="arrival">
      <div v-if="stationRow.arrival.scheduled">
        {{stationRow.arrival.scheduled | toTime}}
      </div>
      <div class="alert cancelled" v-if="stationRow.arrival.cancelled">
        <span v-if="!stationRow.bothCancelled" v-html="$t('cancelled')"></span>
      </div>
      <div class="alert" v-else-if="stationRow.arrival.diff > 1 && stationRow.arrival.actual">
        <span v-if="stationRow.liveEstimateTime">~</span>
        <span v-else>-</span>
        {{stationRow.arrival.actual | toTime}}
      </div>
    </td>
    <td class="departure">
      <div v-if="stationRow.departure.scheduled">
        {{stationRow.departure.scheduled | toTime}}
      </div>
      <div class="alert cancelled" v-if="stationRow.departure.cancelled">
        <span v-if="!stationRow.bothCancelled" v-html="$t('cancelled')"></span>
      </div>
      <div class="alert" v-else-if="stationRow.departure.diff > 1 && stationRow.departure.actual">
         <span v-if="stationRow.liveEstimateTime">~</span>
        <span v-else>-</span>
        {{stationRow.departure.actual | toTime}}
      </div>
    </td>
    <td :class="{'track' : stationRow.track}">
      <div>
        <span v-if="stationRow.track">{{stationRow.track}}</span>
      </div>
    </td>
    <td class="destination">
      <button class="button-reset" @click="setStation" :value="stationRow.station">
        {{stationRow.station | stationShortCodeToName(stations)}}
      </button>
      <div class="alert cancelled" v-if="stationRow.bothCancelled" v-html="$t('cancelled')"></div>
    </td>
    <template v-if="stationRow.dateRow">
      <td class="dateRow" colspan="100">
        {{stationRow.dateRow | toDate}}
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  name: 'StationRow',
  props: {
    stationRow: Object,
  },
  data() {
    return {
      stations: [],
    }
  },
  methods: {
    setStation(event) {
      this.$store.commit('SET_SELECTED_STATION', event.target.value);
    }
  },
  created() {
    this.stations = this.$store.getters.stations;
  },
}
</script>

<style lang="scss" scoped>
tr {
  min-height: 48px;
  &.passed {
    background-color: var(--color-border);
  }
  &:hover {
    background-color: var(--color-accent-light);
    .track div {
      background-color: var(--color-white);
    }
  }
}

td {
  vertical-align: middle;
  text-align: left;
  border-bottom: 2px solid var(--color-tertiary);
  padding: var(--space-xxs);
  font-weight: 500;
}

.trainName {
  text-align: center;
  div {
    margin: 0 auto;
  }

  &.hsl, &.vr {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: var(--body-line-height);
    }
  }
  &.hsl div {
    background-color: var(--color-commuter-hsl);
  }
  &.vr div {
    background-color: var(--color-commuter-vr);
  }
  &.long {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
}

.departure, .arrival {
  text-align: center;
}

.track {
  padding: 0;
  vertical-align: middle;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-accent-light);
    padding: 0;
    vertical-align: bottom;
    border-radius: 2px;
    height: 33px;
    width: 33px;
    margin: 0 auto;
  }

  span {
    line-height: var(--body-line-height);
    font-size: var(--text-sm);
  }
}

.destination button {
  color: var(--color-primary);
  font-size: var(--text-scale-ratio);
  font-weight: 500;
}

.alert {
  color: var(--color-alert);
  text-transform: uppercase;
  font-weight: 500;
}

.cancelled {
  font-size: var(--text-sm);
}

.dateRow {
  text-align: center;
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: var(--space-xxxs);
  font-weight: 300;
}
</style>