<template>
  <div class="station">
    <table v-if="printTrains">
      <thead>
        <tr>
          <th scope="col">{{$t('train')}}</th>
          <th v-if="showArrivals" scope="col">
            <span class="short">{{$t('arrivalShort')}}</span>
            <span class="long">{{$t('arrival')}}</span>
          </th>
          <th v-if="showDepartures" scope="col">
            <span class="short">{{$t('departureShort')}}</span>
            <span class="long">{{$t('departure')}}</span>
          </th>
          <th scope="col">{{$t('track')}}</th>
          <th scope="col" class="destination">
            <span class="short">{{$t('destinationShort')}}</span>
            <span class="long">{{$t('destination')}}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <template v-for="(train, id) in printTrains">
          <TrainRow v-if="train" :trainRow="train" :key="id" />
        </template>
      </tbody>
    </table>
    <div class="loader" :class="{'is-loading' : loading}"></div>
  </div>
</template>

<script>
import TrainRow from './TrainRow.vue';
import RataService from '@/services/RataService';

export default {
  name: 'Station',
  components: {
    TrainRow
  },
  data() {
    return {
      trains: null,
      loading: false,
      printTrains: null,
      stations: this.$store.getters.stations,
    };
  },
  mounted() {
    if (this.station) {
      this.fetchTrains(this.station);
    }
  },
  computed: {
    showArrivals() {
      return this.$store.state.userConfig.showArrivals;
    },
    showDepartures() {
      return this.$store.state.userConfig.showDepartures;
    },
    station() {
      return this.$store.getters.selectedStation;
    },
  },
  methods: {
    refresh() {
      this.fetchTrains(this.station);
    },
    fetchTrains(station) {
      this.loading = true;
      RataService.getTrains({
        amount: this.$store.state.userConfig.showAmount,
        station,
      }).then(trains => {
        this.loading = false;
        this.trains = trains;
      });
    },
    parseTrains() {
      let trains = this.trains;
      trains = trains.filter(train => train.passengerTrain);
      if (!this.$store.state.userConfig.showCommuter) {
        trains = trains.filter(train => !train.isCommuter);
      }
      if (!this.$store.state.userConfig.showLongDistance) {
        trains = trains.filter(train => train.isCommuter)
      }
      if (!this.$store.state.userConfig.showArrivals) {
        trains = trains.filter(train => train.departure.scheduled)
      }
      if (!this.$store.state.userConfig.showDepartures) {
        trains = trains.filter(train => train.arrival.scheduled)
      }

      const todayString = new Date().toISOString();
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate()+1);
      const tomorrowString = tomorrow.toISOString();
      let todayId, tomorrowId;
      for (let i = 0; i < trains.length; i++) {
         if (trains[i].sortingTime.substring(0,10) === todayString.substring(0,10)) {
          todayId = i;
          trains.splice(todayId, 0, {dateRow: todayString});
          break;
        }
      }
      for (let i = 0; i < trains.length; i++) {
        if (trains[i].sortingTime && trains[i].sortingTime.substring(0,10) === tomorrowString.substring(0,10)) {
          tomorrowId = i;
          if (todayId) tomorrowId += 1;
          trains.splice(tomorrowId, 0, {dateRow: tomorrowString});
          break;
        }
      }

      this.printTrains = trains;
    },
  },
  watch: {
    '$store.state.userConfig.showDepartures': function() {
      this.parseTrains();
    },
    '$store.state.userConfig.showArrivals': function() {
      this.parseTrains();
    },
    '$store.state.userConfig.showCommuter': function() {
      this.parseTrains();
    },
    '$store.state.userConfig.showLongDistance': function() {
      this.parseTrains();
    },
    station(station) {
      if (station) {
        this.fetchTrains(station);
      } else {
        this.printTrains = undefined;
      }
    },
    trains() {
      this.parseTrains();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.station {
  overflow-x: scroll;
}

table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
}

th {
  padding: var(--space-xs) var(--space-xxs);
  text-align: center;
  font-weight: 300;
  width: 110px;
  @include tablet-up {  
    width: 130px;
  }
  @include desktop {  
    width: 200px;
  }
}

th.destination {
  text-align: left;
  width: unset;
}

thead tr {
  border-bottom: 2px solid var(--color-primary);
  text-transform: uppercase;
  font-size: var(--text-xs);
}

.long {
  display: none;
}

@include tablet-up {  
  .short {
    display: none;
  }
  .long {
    display: block;
  }
}

tbody {
  position: relative;
}

.loader.is-loading {
  border: 8px solid var(--color-tertiary);
  border-top: 8px solid var(--color-secondary);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  display: block;
  margin: 4rem auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadMore {
  margin: var(--space-md) auto 0 auto;
  display: block;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--color-accent);
  color: var(--color-white);
  padding: var(--space-md);
  font-size: var(--text-sm);
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: var(--color-accent-dark);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
</style>
